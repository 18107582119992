&__github {
  overflow: hidden;
  position: absolute;
  top: 2.4rem;
  right: 5.5rem;
  height: 40;
  width:80;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border-radius: 10%;
  border: 2px solid;
  cursor: pointer;
  animation: bounce 2s infinite;
  @include disableUserSelect();
  @keyframes bounce {
    5%,
    50% {
      transform: scale(1);
    }
  
    10% {
      transform: scale(1);
    }
  
    15% {
      transform: scale(1);
    }
  
    20% {
      transform: scale(1) rotate(-5deg);
    }
  
    25% {
      transform: scale(1) rotate(5deg);
    }
  
    30% {
      transform: scale(1) rotate(-3deg);
    }
  
    35% {
      transform: scale(1) rotate(2deg);
    }
  
    40% {
      transform: scale(1) rotate(0);
    }
  }
  &-btn{
    position: relative;
    text-decoration: inherit;
    color: inherit;
  }
  &::before{
    content: '⭐';
  }
  
  }
  