@mixin placeholder {
  &.placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin disableUserSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
