&__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: auto;
  padding: 0.7rem 0.5rem;

  &-wrp {
    max-width: 95%;
    margin: 0 auto;
  }

  &--text {
    display: flex;
    align-items: center;
    justify-content: center;
    @include fluid-type(768px, 1920px, 12px, 14px);
    font-weight: 500;
    text-align: center;
  }

  &--user {
    display: flex;
    align-items: center;
    color: inherit;
    cursor: pointer;

    &:hover {
      span {
        opacity: 0.7;
      }
    }

    span {
      text-decoration: underline;
      font-weight: 600;
      color: inherit;
      transition: opacity 0.4s $bezier-curve;
    }

    &-icon {
      img {
        margin-left: 0.3rem;
        max-width: 1.5rem;
        border-radius: 50%;
        transition: opacity 0.4s $bezier-curve;
      }
    }
  }

  &--love {
    &-icon {
      img {
        max-width: 1rem;
      }

      &:hover {
        animation: pump 1s infinite;
      }
    }
  }
}
