$white: #fff;
$black: #000;
$light-grey: #f4f4f4;
$grey: #a7a7a7;

// Dark Grey
$ink: #212b36;
$ink-light: #454f5b;
$ink-lighter: #637381;
$ink-lightest: #919eab;

// Light Grey
$sky-dark: #c4cdd5;
$sky: #dfe3e8;
$sky-light: #f4f6f8;
$sky-lighter: #f9fafb;

// Blue
$blue-text: #3e4e57;
$blue-darker: #001429;
$blue-dark: #084e8a;
$blue: #006fbb;
$blue-light: #b4e1fa;
$blue-lighter: #ebf5fa;

// Indigo
$indigo-text: #3e4155;
$indigo-darker: #000639;
$indigo-dark: #202e78;
$indigo: #5c6ac4;
$indigo-light: #b3bcf5;
$indigo-lighter: #f4f5fa;

// Teal
$teal-text: #405352;
$teal-darker: #003135;
$teal-dark: #00848e;
$teal: #47c1bf;
$teal-light: #b7ecec;
$teal-lighter: #e0f5f5;

// Green
$green-text: #414f3e;
$green-darker: #173630;
$green-dark: #108043;
$green: #50b83c; // #86ba38
$green-light: #bbe5b3;
$green-lighter: #e3f1df;

// Red
$red-text: #583c35;
$red-darker: #330101;
$red-dark: #bf0711; // #eb272c
$red: #de3618;
$red-light: #fead9a;
$red-lighter: #fbeae5;

// Purple
$purple-text: rgb(80, 73, 90);
$purple-darker: rgb(35, 0, 81);
$purple-dark: rgb(80, 36, 143);
$purple: rgb(156, 106, 222);
$purple-light: rgb(227, 208, 255);
$purple-lighter: rgb(246, 240, 253);

// Yellow
$yellow-text: #595130;
$yellow-darker: #573b00;
$yellow-dark: #8a6116;
$yellow: #eec200;
$yellow-light: #ffea8a;
$yellow-lighter: #fcf1cd;

// Orange
$orange-text: #594430;
$orange-darker: #4a1504;
$orange-dark: #c05717;
$orange: #f49342;
$orange-light: #ffc58b;
$orange-lighter: #fcebdb;
